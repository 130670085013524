import { render, staticRenderFns } from "./AppUspBlock.vue?vue&type=template&id=4cdcee0e&scoped=true&"
import script from "./AppUspBlock.vue?vue&type=script&lang=js&"
export * from "./AppUspBlock.vue?vue&type=script&lang=js&"
import style0 from "./AppUspBlock.vue?vue&type=style&index=0&id=4cdcee0e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdcee0e",
  null
  
)

export default component.exports